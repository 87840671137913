import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import systemRoutes from './system'
import memberRoutes from './member'
import goodsRoutes from "./goods"
import interestRoutes from "./interest"
import tradeRoutes from "./trade"
import lotteryRoutes from "./lottery"
import loanRoutes from "./loan"
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
  },
  {
    path: '/bind-googlecode',
    name: 'bindGoogleCode',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/bindGoogleCode.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () =>
      import(/* webpackChunkName: "test" */ '@/views/test/index.vue'),
  },
  {
    path: '/',
    name: 'layout',
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/components/layout/layout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () =>
          import(
            /* webpackChunkName: "editPwd" */ '@/views/home/index.vue'
          ),
        meta: {
          title: '修改密码',
        },
      },
      {
        path: '/edit/password',
        name: 'editPwd',
        component: () =>
          import(
            /* webpackChunkName: "editPwd" */ '@/views/editPwd/editPwd.vue'
          ),
        meta: {
          title: '修改密码',
        },
      },
      {
        path: '/edit/language',
        name: 'editLanguage',
        component: () =>
          import(
            /* webpackChunkName: "editPwd" */ '@/views/editLanguage/editLanguage.vue'
          ),
        meta: {
          title: '修改语言',
        },
      },
      ...systemRoutes,
      ...memberRoutes,
      ...goodsRoutes,
      ...interestRoutes,
      ...tradeRoutes,
      ...lotteryRoutes,
      ...loanRoutes

    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from, next) => {
  // console.log(to, from)
  const token = localStorage.getItem('token')
  if (to.path == '/login' && token) {
    next('/')
  }
  if (to.path != '/login' && !token) {
    next('/login')
  }
  next();
})
export default router
